/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact-background {
  // background: url("./component/images/tiled-floor-background.jpg");
  background-size: cover;
  position: relative;
}


.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contact .info {
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #29a5e1;
  float: left;
  width: 44px;
  height: 44px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #111;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #444444;
}

.contact .info:hover i {
  background: #29a5e1;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #29a5e1;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #29a5e1;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #29a5e1;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #29a5e1;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #29a5e1;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
// resources
.resources {
  height: 100px;
  // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
  // background-image: url("../Images/4683.jpg");
  // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
  // background-size:contain;
  background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}

.resources-heading {
  font-family: Georgia, "Times New Roman", Times, serif;
  display: flex;
  justify-content: center;
}

.resources-background {
  // background-image: url("../Images/HomePageImages/luxa.org-opacity-changed-tiled-floor-background.jpg");
  // background-image: url("../Images/HomePageImages/luxa.org-opacity-changed-preview.jpg");
  // background-image: url("../Images/HomePageImages/v880-techi-06-a.jpg");
  background-image: url("../../builders/Images/HomePageImages/luxa.org-opacity-changed-v880-techi-06-a.jpg");
  background-size: contain;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}