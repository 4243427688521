* {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif !important;
}
@media (max-width: 797px) {
  .staffing-title {
    font-size: 27px !important;
  }
}

@media (max-width: 797px) {
  .staffing-content {
    font-size: 16px !important;
  }
}
.no-top-space{
  width: 0px !important;
  height: 0px !important
}
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  /* background: rgba(40, 58, 90, 0.9); */
  background-color: #f5f5f500;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 63px;
}
/* navbar css for text */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #29a5df;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: 600;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #47b2e4;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #31a9e1;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
.font-weight {
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
}
.center-content {
  display: flex;
  width: 100%;
  justify-content: center;
}
.why-choose-image {
  width: 100%;
  color: #5f687b;
}
@media (max-width: 1023px) {
  .why-choose-image {
    width: 75%;
    margin-left: 15%;
    color: #5f687b;
  }
}
.no-wrap {
  white-space: nowrap !important;
}
.why-aviar {
  font-size: 19px !important;
}
.hide-link {
  text-decoration: none;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-22 {
  font-size: 21px !important;
}
.fs-20 {
  font-size: 20px !important;
}
@media (max-width: 797px) {
  .fs-20 {
    font-size: 17px !important;
  }
}

.fs-25 {
  font-size: 20px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 400;
}

.fs-normal {
  font-style: normal !important;
}
.background_none {
  background: none !important;
}
.tab_row {
  border-bottom: 2px solid #c9c4c4;
  width: 110px;
}
.nav-pills .nav-link.active {
  color: #040506 !important;
  border-bottom: 3px solid #009bdf;
  border-radius: 0%;
  background-color: #fff !important;
}
.m-t-8 {
  margin-top: 8% !important;
}
.f-x-l {
  font-size: x-large !important;
}
.f-xx-l {
  font-size:67px !important;
  width: 70;
  cursor: pointer;
}
.f-i-h {
  font-size: inherit !important;
}
.ul {
  list-style-type: none;
}
.c-end {
  display: flex !important;
  justify-content: flex-end !important;
}

/* //mobile navaar responsive */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position:fixed ;
  /* overflow: hidden; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.65);
  transition: 0.3s;
  z-index: 999;
}


.navbar-mobile .mobile-nav-toggle {
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 10px 0;
  border-radius: 0px;
  background-color: #ffff;
  /* background-image: url("./components/Images/menu-bg.jpg"); */
  background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
  scrollbar-width: none !important;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #47b2e4;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #0e78aa;
  font-size: 25px;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #37517e;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #0b4c6a;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

@media (min-width: 991px) and (max-width: 2560px) {
  .hide-modal {
    display: none !important;
  }
}
.agile-padding {
  padding: 0px 0px !important;
  overflow: hidden;
}
.m-s-2 {
  margin-left: 1.5rem !important;
}
@media (max-width: 1390px) {
  .m-s-2 {
    margin-left: 0rem !important;
  }
}
.background-remove{
  background-color:#1d27554a !important ;
  background-image: none !important;
}
.team-left{
margin-left: 6% !important;
}
.team-col-left {
  margin-left:6rem !important;
}
@media(max-width:1078px){
.team-col-left {
    margin-left: 4rem !important;
  }
}
@media(max-width:767px) {
  .team-col-left {
    margin-left: 2rem !important;
  }
}
.team-left {
  margin-left: 6% !important;
}

.team-col-1-left {
  margin-left: 3rem !important;
}

@media(max-width:1078px) {
  .team-col-1-left {
    margin-left: 1rem !important;
  }
}

@media(max-width:460px) {
  .team-col-1-left {
    margin-left: 1rem !important;
  }
}


@media(min-width:320px) and (max-width:992px){
  .team-col-width{
    width:82% !important
  }
}
@media(max-width:425px){
.team-media-font{
  font-size: 16px !important;
}
}
@media(min-width:320px) and (max-width:992px) {
  .team-col-1-width {
    width: 92% !important
  }
}

@media(max-width:425px) {
  .team-media-font {
    font-size: 16px !important;
  }
}
@media(min-width:426px)and(max-width:1399px) {
  .team-media-font {
    font-size: 16px !important;
  }
}
.letter-spacing{
  letter-spacing: 2px;
}

.content-margin{    
margin-top: -4.5rem !important;
}
.Dot-Remove {
  list-style-type: none;
}
.modal-style{
  border: 0 !important;
  width: 0 !important;
}
/* hide arrows */
/* hide arrows
 Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}