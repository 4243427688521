.builders-community-links-title {
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
  font-size: 24px;
  padding: 0 5px 5px;
  font-weight: 200;
  margin-bottom: 15px;
  border-bottom: 3px solid #47b2e4;
}

.resources {
  height: 100px;
  // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
  // background-image: url("../Images/4683.jpg");
  // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
  // background-size:contain;
  background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}

.resources-background {
  // background-image: url("../Images/HomePageImages/luxa.org-opacity-changed-tiled-floor-background.jpg");
  // background-image: url("../Images/HomePageImages/luxa.org-opacity-changed-preview.jpg");
  // background-image: url("../Images/HomePageImages/v880-techi-06-a.jpg");
  background-image: url("../../builders/Images/HomePageImages/luxa.org-opacity-changed-v880-techi-06-a.jpg");
  background-size: contain;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}

.resources-div {
  display: flex;
  align-items: center;
}

.resources-align {
  width: 85%;
  text-align: start;
}

.resources-heading {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.builder-trend-img {
  margin-right: 20px;
  margin-bottom: 10px;
}

// recomended partner
.partner-name {
  position: relative;
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  padding: 0 10px 15px;
  font-weight: 200;
  margin-bottom: 15px;
  border-bottom: 1px solid #bdab10;
}
.tab-font {
  font-size: 18px;
  font-weight: 300px;
  --bs-nav-tabs-link-active-color: #0dcaf0;
  --bs-border-width: 3px;
  // --bs-nav-link-color: #000;
  // --bs-nav-tabs-border-color: #000;
  // --bs-nav-tabs-background-color: #dee2e600;
  --bs-nav-tabs-link-active-bg: #fff0;
  // --bs-nav-tabs-link-active-border-color: #000;
  // --bs-nav-tabs-link-hover-border-color: #fff0;
}

hr {
  color: brown;
  --bs-border-width: 2px;
}
.contain {
  height: 100px;
  background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .contain {
    height: 100%;
    background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}
