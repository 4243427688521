.bgc {
    background-color: #29a5e1;
    text-align: center;
  }
  .contain {
    height: 100px;
    display: flex;
    justify-content: center;
    // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
    // background-image: url("../Images/4683.jpg");
    // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
    // background-size:contain;
    background-image: url("../components/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
  }
  @media (max-width: 767px) {
    .contain {
      height: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
      // background-image: url("../Images/4683.jpg");
      // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
      // background-size:contain;
      background-image: url("../components/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
    }
  }
  .aboutcard {
    width: 100%;
    height: 300px;
    border-width: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homepage-aboutcard {
    width: 100%;
    height: 300px;
    border-width: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Adjust styles for the image container */
  .image-container {
    display: flex;
    flex-direction: column; /* Display images vertically */
    align-items: center; /* Center images horizontally */
  }
  
  @media (max-width: 767px) {
    .aboutcard {
      flex-direction: column;
      text-align: center;
      width: 100%;
      background-color: white;
    }
  }
  @media (max-width: 767px) {
    .homepage-aboutcard {
      flex-direction: column;
      text-align: center;
      width: 100%;
      background-color: white;
    }
  }
  
  .s2-section {
    display: flex;
    border-radius: 5px;
    margin-bottom: -100px; 
  }
  .card-left {
    height: 100%;
    width: 40%;
    background-image: url("../components/Images/modern-equipped-computer-lab.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 5px;
  }
  @media (max-width: 767px) {
    .card-left {
      height: 100%;
      width: 100%;
      background-image: url("../components/Images/modern-equipped-computer-lab.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 5px;
    }
  }
  .card-right {
    height: 100%;
    width: 60%;
    margin-left: 20px;
    
    // background-image: url("../Images/HomePageImages/8104960.jpg");
    // background-size: cover;
    // background-position: center;
    // position: relative;
  }
  .intro-card {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #29a5e1;
  }
  @media (max-width: 767px) {
    .intro-card {
      height: 100%;
      display: flex;
      justify-content: center;
      background-color: #29a5e1;
    }
  }
  .intro-card-right {
    height: 100%;
    width: 68%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .intro-card-left {
    height: 80%;
    width: 30%;
    background-image: url("../components/Images/HomePageImages/20945391.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  @media (max-width: 767px) {
    .intro-card-left {
      height: 100%;
      width: 100%;
      background-image: url("../components/Images/HomePageImages/20945391.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
    }
  }
  
  .intro-font {
    font-size: 28px !important;
    width: 75%;
    color: white;
    font-weight: 100;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (max-width: 767px) {
    .intro-font {
      font-size: 18px !important;
      color: white;
      width: 100%;
      font-weight: 100;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .intro-title {
    font-size: 40px !important;
    font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif !important;
    color: white;
    font-weight: bold;
    padding-top: 15px;
  }
  @media (max-width: 767px) {
    .intro-title {
      font-size: 24px !important;
      font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif !important;
      color: white;
      font-weight: bold;
      padding-top: 15px;
    }
  }
  .card2-left {
    height: 100%;
    width: 40%;
    background-image: url("../components/Images/HomePageImages/working-place-real-estate-agent.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 5px;
  }
  @media (max-width: 767px) {
    .card2-left {
      height: 100%;
      width: 100%;
      background-image: url("../components/Images/HomePageImages/working-place-real-estate-agent.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 5px;
    }
  }
  .card3-left {
    height: 100%;
    width: 40%;
    // background-image: url("../Images/HomePageImages/20945830.jpg");
    background-image: url("../components/Images/HomePageImages/important-information-three-men-white-shirt-protective-helmet-woman-business-suit-with-drawings-looking-into-laptop-standing-building-construction.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    background-color: #60a7cabc;
    border-radius: 5px;
  }
  @media (max-width: 767px) {
    .card3-left {
      height: 100%;
      width: 100%;
      background-image: url("../components/Images/HomePageImages/important-information-three-men-white-shirt-protective-helmet-woman-business-suit-with-drawings-looking-into-laptop-standing-building-construction.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
      background-color: #29a5e1;
      border-radius: 5px;
    }
  }
  .at-logo {
    height: 30%;
    // width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px;
  }
  .at-logo2 {
    width: 15%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .at-logo_title {
    width: 30%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px;
    background-color: red($color: #000000);
  }
  .at-logo3 {
    height: 30%;
    // width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px;
  }
  @media (max-width: 767px) {
    .at-logo {
      height: 30%;
      // width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      border-radius: 5px;
    }
  }
  .at-logo2 {
    height: 30%;
    // width: 80%;
    margin-top: 8%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px;
  }
  .at-logo3 {
    height: 30%;
    // width: 80%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px;
  }
  
  .card-div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 767px) {
    .card-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 8px;
    }
  }
  .intro-title1 {
    font-size: 40px !important;
    font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif !important;
    color: white;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    .intro-title1 {
      font-size: 23px !important;
      font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif !important;
      color: white;
      font-weight: bold;
    }
  }
  
  