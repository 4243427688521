// wave for menu icon
// @mixin rings($duration, $delay) {
//   opacity: 0;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   right: 0;
//   bottom: 0;
//   content: "";
//   border: 8px solid rgba(0, 0, 0, 0.2);
//   border-radius: 100%;
//   animation-name: ripple;
//   animation-duration: $duration;
//   animation-delay: $delay;
//   animation-iteration-count: infinite;
//   animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
//   z-index: -1;
// }

// .request-loader {
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   // height: 70px;
//   width: 70px;
//   border-radius: 100%;
//   background: #ffffff;
//   // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);

//   span {
//     position: relative;
//     font-size: 72px;
//     top: 5px;
//     left: -5px;
//   }

//   &::after {
//     @include rings(3s, 0s);
//   }

//   &::before {
//     @include rings(3s, 0.5s);
//   }
// }

// @keyframes ripple {
//   from {
//     opacity: 1;
//     transform: scale3d(0.75, 0.75, 1);
//   }

//   to {
//     opacity: 0;
//     transform: scale3d(1.5, 1.5, 1);
//   }
// }
// .request-loader:hover {
//   transform: scale(1);
// }
// // wave modal
// @keyframes waveAnimation {
//   0% {
//     transform: scale(0);
//     opacity: 0;
//   }

//   50% {
//     transform: scale(1.2);
//     opacity: 0.8;
//   }

//   100% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }


.modal-enter {
  animation: waveAnimation 500ms ease-in;
}

.modal-exit {
  animation: waveAnimation 500ms ease-out reverse;
}

// @media (max-width: 767px) {
//   .menu-media {
//     margin-left: 1rem !important;
//     margin-top: 0rem !important;
//   }
// }

@media (max-width: 767px) {
  .logo-media {
    margin-left: -6rem !important;
  }
}

@media (max-width: 767px) {
  .media-menu-option {
    margin-left: 0% !important;
    margin-top: 3% !important;
  }
}

@media (max-width: 1116px) {
  .menu-text {
    font-size: 16px !important;
  }
}

:root {
  --color-text: #fbd54a;
  --color-bg: #333;
  --color-link: #4d4f5f;
  --color-link-hover: #bfb5b5;
  --color-info: #fff;
  --color-main-bg: #24262d;
  --path-fill-1: #411818;
  --path-fill-2: #2525a6;
  --path-fill-3: #e6e3dc;
  --color-menu: #000000;
  --color-menu-hover: #496e9b;
  --font-family-menu: "Arapey", serif;
  --font-size-menu: 50px;
  --font-weight-menu: 400;
  --button-bg: #fff;
  --button-circle: #fff;
  --button-line: #222;
}

/* Fade effect */
.js body {
  opacity: 0;
  transition: opacity 0.3s;
}

.js body.render {
  opacity: 1;
}

a {
  text-decoration: none;
  color: #5d93d8;
  color: var(--color-link);
}

a:hover,
a:focus {
  color: #423c2b;
  color: var(--color-link-hover);
  outline: none;
}

.global-menu {
  position: fixed;
  display: flex;
  z-index: 100;
}

.global-menu__item {
  opacity: 0;
  pointer-events: none;
  display: block;
  transition: transform 2s, opacity 2s;
  transform: translateY(100%);
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    color: var(--color-menu-hover);
  }

  &.is-opened {
    opacity: 1;
    transform: translateY(0) rotate(0);
    pointer-events: auto;
    transition-timing-function: ease;
  }

  &:nth-of-type(1) {
    //transition-delay: 0s;
  }

  &.is-opened:nth-of-type(1) {
    transition-delay: 0.65s;
  }

  &:nth-of-type(2) {
    //transition-delay: 0s;
  }

  &.is-opened:nth-of-type(2) {
    transition-delay: 0.7s;
  }

  &:nth-of-type(3) {
    //transition-delay: 0s;
  }

  &.is-opened:nth-of-type(3) {
    transition-delay: 0.75s;
  }

  &:nth-of-type(4) {
    //transition-delay: 0s;
  }

  &.is-opened:nth-of-type(4) {
    transition-delay: 0.8s;
  }

  &.is-opened:nth-of-type(5) {
    transition-delay: 0.9s;
  }

  &.is-opened:nth-of-type(6) {
    transition-delay: 0.95s;
  }

  &.is-opened:nth-of-type(7) {
    transition-delay: 1s;
  }

  &.is-opened:nth-of-type(8) {
    transition-delay: 1.1s;
  }
}

.shape-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &.is-opened {
    pointer-events: auto;
  }
}

.shape-overlays__path:nth-of-type(1) {
  fill: #00539c !important;
}

.shape-overlays__path:nth-of-type(2) {
  fill: #29a5e1 !important;
}

.shape-overlays__path:nth-of-type(3) {
  fill: #fff !important;
}

.shape-overlays__path:nth-of-type(4) {
  fill: var(--path-fill-4);
}

@-webkit-keyframes intervalHamburgerBorder {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    transform: scale(1.6);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

@keyframes intervalHamburgerBorder {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    transform: scale(1.6);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

.hamburger {
  width: 64px;
  height: 64px;
  display: block;
  position: relative;
  cursor: pointer;
  top: 2.25em;
  left: 2.25em;
  z-index: 110;
  border-radius: 50%;
  background-color: var(--button-bg);
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hamburger::after {
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  border: 4px solid var(--button-circle);
  border-radius: 50%;
  animation-duration: 1.2s;
  animation-name: intervalHamburgerBorder;
  animation-iteration-count: infinite;
}

.hamburger__line {
  width: 28px;
  height: 2px;
  overflow: hidden;
  position: absolute;
  z-index: 10;
}

.hamburger__line-in {
  width: 84px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.hamburger__line-in::before,
.hamburger__line-in::after {
  width: 28px;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-color: #29a5e1 !important;
}

.hamburger__line-in::before {
  left: -56px;
}

.hamburger__line-in::after {
  left: 0;
}

.hamburger__line--01,
.hamburger__line--02,
.hamburger__line--03,
.hamburger__line--cross01,
.hamburger__line--cross02 {
  left: 18px;
}

.hamburger__line--01 {
  top: 24.6px;
}

.hamburger__line--02,
.hamburger__line--cross01,
.hamburger__line--cross02 {
  top: 31px;
}

.hamburger__line--03 {
  top: 37.4px;
}

.hamburger__line--cross01 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger__line--cross02 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger__line {
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in {
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in::before,
.hamburger__line-in::after {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: transform;
}

.hamburger__line-in--cross01,
.hamburger__line-in--cross02 {
  transform: translateX(-33.3%);
}

.hamburger__line-in--01 {
  transition-delay: 0.2s;
}

.hamburger__line-in--02 {
  transition-delay: 0.25s;
}

.hamburger__line-in--02::before,
.hamburger__line-in--02::after {
  transition-delay: 0.05s;
}

.hamburger__line-in--03 {
  transition-delay: 0.3s;
}

.hamburger__line-in--03::before,
.hamburger__line-in--03::after {
  transition-delay: 0.1s;
}

.hamburger__line-in--cross01 {
  transition-delay: 0s;
}

.hamburger__line-in--cross02 {
  transition-delay: 0.05s;
}

.hamburger__line-in--cross02::before,
.hamburger__line-in--cross02::after {
  transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--01,
.hamburger.is-opened-navi .hamburger__line-in--02,
.hamburger.is-opened-navi .hamburger__line-in--03 {
  transform: translateX(33.3%);
}

.hamburger.is-opened-navi .hamburger__line-in--cross01,
.hamburger.is-opened-navi .hamburger__line-in--cross02 {
  transform: translateX(0);
}

.hamburger.is-opened-navi .hamburger__line-in--01 {
  transition-delay: 0s;
}

.hamburger.is-opened-navi .hamburger__line-in--02 {
  transition-delay: 0.05s;
}

.hamburger.is-opened-navi .hamburger__line-in--03 {
  transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
  transition-delay: 0.25s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
  transition-delay: 0.3s;
}

.hamburger:hover .hamburger__line-in::before,
.hamburger:hover .hamburger__line-in::after {
  transform: translateX(200%);
}

.hamburger:hover .hamburger__line-in--01::before,
.hamburger:hover .hamburger__line-in--01::after,
.hamburger:hover .hamburger__line-in--02::before,
.hamburger:hover .hamburger__line-in--02::after,
.hamburger:hover .hamburger__line-in--03::before,
.hamburger:hover .hamburger__line-in--03::after {
  transition-duration: 1s;
}

.hamburger:hover .hamburger__line-in--cross01::before,
.hamburger:hover .hamburger__line-in--cross01::after,
.hamburger:hover .hamburger__line-in--cross02::before,
.hamburger:hover .hamburger__line-in--cross02::after {
  transition-duration: 0s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after {
  transition-duration: 1s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::after {
  transition-duration: 0s;
}

@media screen and (max-width: 55em) {
  .hamburger {
  position: absolute;
    top: 0.5em;
    left: 0.4em !important;
  }
}
@media(min-width:992px)and (max-width:1145px) {
  .hamburger {
    position: absolute;
    top: 0.7em;
    left: 2.4em !important;
  }
}