.resources {
  height: 100px;
  // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
  // background-image: url("../Images/4683.jpg");
  // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
  // background-size:contain;
  background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}
.resources-heading {
  font-family: Georgia, "Times New Roman", Times, serif;
  display: flex;
  justify-content: center;
}

.resources-background {
  // background-image: url("../Images/HomePageImages/luxa.org-opacity-changed-tiled-floor-background.jpg");
  // background-image: url("../Images/HomePageImages/luxa.org-opacity-changed-preview.jpg");
  // background-image: url("../Images/HomePageImages/v880-techi-06-a.jpg");
  background-image: url("../../builders/Images/HomePageImages/luxa.org-opacity-changed-v880-techi-06-a.jpg");
  background-size: contain;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}
/*------/ Intro Single /------*/
.intro-single {
  padding: 12rem 0 3rem;
}

@media (max-width: 767px) {
  .intro-single {
    padding-top: 9rem;
  }
}

.intro-single .title-single-box {
  padding: 1rem 0 1rem 2rem;
}

.intro-single .title-single-box {
  border-left: 3px solid #47b2e4;
}

.intro-single .title-single-box .title-single {
  font-weight: 600;
  font-size: 2.2rem;
}

@media (min-width: 768px) {
  .intro-single .title-single-box .title-single {
    font-size: 2.1rem;
  }
}

@media (min-width: 992px) {
  .intro-single .title-single-box .title-single {
    font-size: 2.5rem;
  }
}

.intro-single .breadcrumb-box {
  padding: 1rem 0 0 0.5rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}

/*--------------------------------------------------------------
# Card General
--------------------------------------------------------------*/
@media (max-width: 767px) {
  .card-box-a,
  .card-box-b,
  .card-box-c,
  .card-box-d {
    margin-bottom: 2.5rem;
    border-radius: 5px;
  }
}

.card-box-a span,
.card-box-b span,
.card-box-c span,
.card-box-d span {
  line-height: 0;
}

@media (min-width: 768px) {
  .grid .card-box-a,
  .grid .card-box-b,
  .grid .card-box-c,
  .grid .card-box-d {
    margin-bottom: 2.5rem;
  }
}

.card-box-a,
.card-box-b,
.card-box-d {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-box-a .img-a,
.card-box-a .img-b,
.card-box-b .img-a,
.card-box-b .img-b {
  transition: 0.8s all ease-in-out;
}

@media (min-width: 768px) {
  .card-box-a:hover .img-a,
  .card-box-a:hover .img-b,
  .card-box-b:hover .img-a,
  .card-box-b:hover .img-b {
    transform: scale(1.2);
  }
}

@media (min-width: 768px) {
  .card-box-a .price-a,
  .card-box-b .price-a {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .card-box-a .price-a,
  .card-box-b .price-a {
    font-size: 1rem;
  }
}

.card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card-shadow {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-shadow:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}

/*======================================
//--//-->   PROPERTY - CARD-A
======================================*/
@media (min-width: 768px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 60px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 60px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 64px;
  }
}

@media (min-width: 768px) {
  .card-box-a:hover .card-body-a {
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .card-box-a .card-overlay-a-content {
    bottom: 65px;
  }

  .card-box-a .card-body-a {
    padding-bottom: 1rem;
  }
}

.card-overlay-a-content {
  transition: all 0.5s ease-in;
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 2;
}

@media (min-width: 768px) {
  .card-overlay-a-content {
    bottom: -20px;
  }
}

@media (min-width: 992px) {
  .card-overlay-a-content {
    bottom: 0px;
  }
}

.card-header-a {
  padding: 0 1rem;
}

.card-header-a .card-title-a {
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 0.7rem;
}

@media (min-width: 768px) {
  .card-header-a .card-title-a {
    font-size: 1.3rem;
  }
}

@media (min-width: 992px) {
  .card-header-a .card-title-a {
    font-size: 2rem;
  }
}

.card-header-a .card-title-a a {
  color: #ffffff;
  text-decoration: none;
}

.card-body-a {
  z-index: 2;
  transition: all 0.5s ease-in;
  padding: 0rem 1rem 2rem 1rem;
}

.card-body-a .price-box {
  padding-bottom: 0.5rem;
}

.price-a {
  color: #ffffff;
  padding: 0.6rem 0.8rem;
  border: 2px solid #47b2e4;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.card-footer-a {
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: #47b2e4;
}

.card-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0.5rem 0;
}

.card-info .card-info-title {
  font-size: 1rem;
  color: #313131;
}

@media (min-width: 768px) {
  .card-info .card-info-title {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .card-info .card-info-title {
    font-size: 1rem;
  }
}

.card-info li span {
  color: #ffffff;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .card-info li span {
    font-size: 0.8rem;
  }
}

@media (min-width: 992px) {
  .card-info li span {
    font-size: 1rem;
  }
}

/*------/ Property Grid /-----*/
.property-grid .grid-option {
  text-align: right;
  margin-bottom: 2rem;
}

.property-grid .grid-option .custom-select {
  border-radius: 0;
  width: 200px;
  border-color: #313131;
}

.property-grid .grid-option .custom-select:hover,
.property-grid .grid-option .custom-select:active,
.property-grid .grid-option .custom-select:focus {
  border-color: #313131;
}

.property-grid .grid-option .custom-select option {
  border-color: #313131;
}
/*------/ Pagination-a /------*/
.pagination-a {
  text-decoration: none;
}

.pagination-a .pagination .page-link {
  margin: 0 0.2rem;
  border-color: transparent;
  padding: 0.5rem 0.8rem;
  color: #000000;
}

.pagination-a .pagination .page-link:hover,
.pagination-a .pagination .page-link:active,
.pagination-a .pagination .page-link:focus {
  background-color: #47b2e4;
}

.pagination-a .pagination .page-link span {
  font-size: 1.2rem;
}

.pagination-a .pagination .next .page-link {
  padding: 0.4rem 0.9rem;
}

.pagination-a .pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-a .pagination .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-a .pagination .page-item.disabled .page-link {
  padding: 0.4rem 0.9rem;
  color: #555555;
}

.pagination-a .pagination .page-item.active .page-link {
  background-color: #47b2e4;
}

.a {
  text-decoration: none;
  color: #47b2e4;
  font-size: 1.3rem;
}
// tabs
.tab-font {
  font-size: 18px;
  font-weight: 300px;
  --bs-nav-tabs-link-active-color: #0dcaf0;
  --bs-border-width: 3px;
  // --bs-nav-link-color: #000;
  // --bs-nav-tabs-border-color: #000;
  // --bs-nav-tabs-background-color: #dee2e600;
  --bs-nav-tabs-link-active-bg: #fff0;
  // --bs-nav-tabs-link-active-border-color: #000;
  // --bs-nav-tabs-link-hover-border-color: #fff0;
}
.contain {
  height: 100px;
  // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
  // background-image: url("../Images/4683.jpg");
  // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
  // background-size:contain;
  background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .contain {
    height: 100%;
    // background-image: url("../Images/blur-ceramic-mosaic-background.jpg");
    // background-image: url("../Images/4683.jpg");
    // background-image: url("../Images/luxa.org-opacity-changed-6701995.jpg");
    // background-size:contain;
    background-image: url("../../builders/Images/luxa.org-opacity-changed-old-painted-textured-surface-backdrop.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}
hr {
  color: brown;
  --bs-border-width: 2px;
}
.click-view {
  color: white;
}
